import { defineStore } from "pinia";
import { useAuthStore } from "~/store/auth/auth.store";
import { useFeedback } from "~~/composables/feedback/useFeedback";
import { useRequestsValidation } from "~~/composables/requests/useRequests";
import { useRequestsApi } from "~~/composables/requests/useRequestsApi";
import { useOffCatalogRequests } from "~~/store/catalog/off-catalog-requests.store";
import type { ICreateRequest } from "~~/utils/interfaces/requests-interfaces";

export const useCreateRequestStore = defineStore("createRequestStore", {
  state: (): ICreateRequest => {
    return {
      request: {
        training_id: null,
        session_id: null,
        users_to_invite: [],
        employee_comment: null,
        manager_priority: null,
        manager_comment: null,
        is_unqualified: false,
      },
      nav: null,
      isCheckedForMe: false,
      isCheckedForOther: false,
      show_modal: false,
      show_exit: false,
      show_confirm: false,
      users_error: null,
      employee_comment_error: null,
      manager_priority_error: null,
      radio_error: null,
      stepModal: null,
      noSessionSelected: false,
      isDesktop: false,
    };
  },
  getters: {
    checkCreateOtherRequestError(): boolean {
      if (this.employee_comment_error || this.manager_priority_error || this.radio_error || this.users_error) {
        return true;
      } else {
        return false;
      }
    },
  },
  actions: {
    openRequestModal() {
      this.resetStore();
      this.show_modal = true;
    },
    resetStore() {
      const saveSession = this.request.session_id;
      const saveTrainingId = this.request.training_id;
      this.$reset();
      this.request.session_id = saveSession;
      this.request.training_id = saveTrainingId;
    },
    resetRequest() {
      this.request.users_to_invite = [];
      this.request.employee_comment = null;
      this.request.manager_priority = null;
      this.request.manager_comment = null;
    },
    resetRequestError() {
      this.employee_comment_error = null;
      this.manager_priority_error = null;
      this.users_error = null;
    },
    async validateManagerRequestCreation() {
      const requestsValidation = useRequestsValidation();
      const t$ = await requestsValidation.validateManagerRequestsCreation();

      if (t$.value.$errors) {
        this.users_error = t$.value.users_to_invite?.$silentErrors.map((m: any) => m.$message).join("") ?? "";
        // this.users_error = t$.value.users_id?.$silentErrors.map((m: any) => m.$message).join("") ?? "";
        this.employee_comment_error = t$.value.employee_comment?.$silentErrors.map((m) => m.$message).join("") ?? "";
        this.manager_priority_error = t$.value.manager_priority?.$silentErrors.map((m) => m.$message).join("") ?? "";
        // this.radio_error = t$.value.isCheckedForOther?.$silentErrors.map((m: any) => m.$message).join("") ?? "";
      }
    },
    async validateRequestCreation() {
      const requestsValidation = useRequestsValidation();
      const t$ = await requestsValidation.validateRequestsCreation();
      if (t$.value.$errors) {
        this.employee_comment_error = t$.value.employee_comment?.$silentErrors.map((m) => m.$message).join("") ?? "";
      }
    },

    async createRequest(isOther: boolean = false) {
      const authStore = useAuthStore();
      if (
        ((authStore.isManager || authStore.isCompanyAdmin || authStore.isGroupViewer || authStore.isGroupAdmin) &&
          this.isDesktop) ||
        isOther
      ) {
        return await this.createOtherRequest();
      } else {
        return await this.createEmployeeRequest();
      }
    },

    async createEmployeeRequest() {
      const requestsApi = useRequestsApi();
      const authStore = useAuthStore();
      const feedback = useFeedback();
      const offCatalogRequests = useOffCatalogRequests();

      await this.validateRequestCreation();
      if (authStore.user?.user_id && this.request.employee_comment && !this.employee_comment_error) {
        if (offCatalogRequests?.training?.training) {
          offCatalogRequests.training.employee_comment = this.request.employee_comment;
          await offCatalogRequests.createOffCatalogRequest();
        } else {
          const { data } = await requestsApi.createRequest({
            training_id: this.request.session_id ? null : this.request.training_id,
            session_id: this.request.session_id?.session_id,
            users_id: [authStore.user.user_id],
            employee_comment: this.request.employee_comment,
            is_unqualified: !(this.request.session_id || this.request.training_id),
          });

          if (data) {
            this.show_modal = false;
            this.request.session_id = null;
            navigateTo(`/my-trainings/${data.value.requests[0]}`);
            feedback.success("Votre demande a bien été enregistrée", "small");
          } else {
            feedback.error(`Une erreur est survenue lors de l'ajout`, "small");
          }

          return data.value;
        }
      }
    },

    async createOtherRequest() {
      const requestsApi = useRequestsApi();
      const authStore = useAuthStore();
      const feedback = useFeedback();
      const offCatalogRequests = useOffCatalogRequests();

      await this.validateManagerRequestCreation();

      if (
        (this.request.users_to_invite || authStore.user?.user_id) &&
        this.request.employee_comment &&
        !this.checkCreateOtherRequestError
      ) {
        if (offCatalogRequests?.training?.training) {
          offCatalogRequests.training.employee_comment = this.request.employee_comment;
          await offCatalogRequests.createOffCatalogRequest();
        } else if (authStore.user?.user_id) {
          const { data } = await requestsApi.createSpecificRequest({
            training_id: this.request.session_id ? null : this.request.training_id,
            session_id: this.request.session_id?.session_id,
            users_id: this.request.users_to_invite.length
              ? this.request.users_to_invite?.map((u) => u.user_id)
              : [authStore.user?.user_id],
            employee_comment: this.request.employee_comment,
            manager_id: authStore.user?.user_id ?? null,
            ...(this.request.manager_priority && {
              manager_priority: this.request.manager_priority?.key,
            }),
            ...(this.request.manager_comment && {
              manager_comment: this.request.manager_comment,
            }),
            is_unqualified: !(this.request.session_id || this.request.training_id),
          });

          this.isCheckedForMe = !this.request.users_to_invite.some((u) => u.user_id !== authStore.user?.user_id);
          this.show_modal = false;

          if (data) {
            this.show_modal = false;

            const router = useRoute();

            const home = router.fullPath === "/home";
            if (!home) this.show_confirm = true;

            feedback.success("Votre demande a bien été enregistrée", "small");
          } else {
            feedback.error(`Une erreur est survenue lors de l'ajout`, "small");
          }

          return data.value;
        }
      }
    },
  },
});
