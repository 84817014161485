import { storeToRefs } from "pinia";
import { helpers, required, url } from "@vuelidate/validators";

import useVuelidate from "@vuelidate/core";
import { useOffCatalogRequests } from "~/store/catalog/off-catalog-requests.store";

export const useOffCatalogValidator = () => {
  async function validateStepTraining() {
    const offCatalogRequests = useOffCatalogRequests();
    const { training } = storeToRefs(offCatalogRequests);

    const rules = computed(() => {
      return {
        training_partner: {
          required: helpers.withMessage("Ce champ est obligatoire", required),
        },
        training: {
          required: helpers.withMessage("Ce champ est obligatoire", required),
        },
        external_url: {
          required: helpers.withMessage("Ce champ est obligatoire", required),
          url: helpers.withMessage("Lien invalide", url),
        },
      };
    });
    const si$ = useVuelidate(rules, training.value);
    await si$.value.$validate();

    offCatalogRequests.pushValidationErrors(si$.value.$errors);
  }

  async function validateStepEmployeeComment() {
    const offCatalogRequests = useOffCatalogRequests();
    const { training } = storeToRefs(offCatalogRequests);

    const rules = computed(() => {
      return {
        employee_comment: {
          required: helpers.withMessage("Ce champ est obligatoire", required),
        },
      };
    });
    const si$ = useVuelidate(rules, training.value);
    await si$.value.$validate();

    offCatalogRequests.pushValidationErrors(si$.value.$errors);
  }

  return {
    validateStepTraining,
    validateStepEmployeeComment,
  };
};
