import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { storeToRefs } from "pinia";
import { SettingScopesEnum } from "@simbelapp/auth-sdk";
import type { IIndexHeader } from "~/utils/interfaces/table-interfaces";
import { RequestStatus } from "~~/utils/enums/request.enums";
import type { IFooterActions, IGeneric } from "~~/utils/interfaces/common-interfaces";
import { useCreateRequestStore } from "~~/store/requests/create-request.store";
import { ProjectTypeEnum } from "~/utils/enums/project.enums";
import { useRequestsStore } from "~/store/requests/requests.store";
import type { IGetSelectedRequests } from "~/utils/interfaces/requests-interfaces";
import { useCreateProjectCoreStore } from "~/store/project/create-project-core.store";
import { ItemType } from "~/utils/enums/common.enums";
import { useAuthStore } from "~/store/auth/auth.store";

export const useRequests = () => {
  const headers = useState<IIndexHeader[]>(() => [
    {
      name: "Demandée le",
      sort: true,
      key: "created_at",
      minWidthPx: 110,
      maxWidthFr: 1.5,
      visible: true,
      scope: SettingScopesEnum.RequestsTab,
    },
    {
      name: "Employé",
      sort: true,
      key: "lastname",
      minWidthPx: 201,
      maxWidthFr: 2,
      visible: true,
      scope: SettingScopesEnum.RequestsTab,
    },
    {
      name: "Formation",
      sort: true,
      key: "training_title",
      minWidthPx: 259,
      maxWidthFr: 4,
      visible: true,
      scope: SettingScopesEnum.RequestsTab,
    },
    {
      name: "Prix (HT)",
      key: "price_excl_tax",
      sort: true,
      minWidthPx: 110,
      maxWidthFr: 1.5,
      visible: true,
      scope: SettingScopesEnum.RequestsTab,
    },
    {
      name: "Priorité",
      key: "priority",
      sort: true,
      minWidthPx: 90,
      maxWidthFr: 1,
      visible: true,
      scope: SettingScopesEnum.RequestsTab,
    },
    {
      name: "Manager",
      sort: true,
      key: "manager_firstname",
      minWidthPx: 155,
      maxWidthFr: 2,
      visible: true,
      scope: SettingScopesEnum.RequestsTab,
    },
    {
      name: "Statut",
      sort: true,
      key: "status",
      minWidthPx: 270,
      maxWidthFr: 3,
      visible: true,
      scope: SettingScopesEnum.RequestsTab,
    },
  ]);

  function statusMapping(value: string) {
    return [
      {
        name: RequestStatus.WAITING_MANAGER_INPUT,
        value: "Indications du manager en attente",
        type: "light_orange",
      },
      {
        name: RequestStatus.WAITING_QUALIFICATION,
        value: "Validation RH en attente",
        type: "light_orange",
      },
      {
        name: RequestStatus.WAITING_HR_VALIDATION,
        value: "Validation RH en attente",
        type: "light_orange",
      },
      {
        name: RequestStatus.REQUEST_REFUSED,
        value: "Demande refusée",
        type: "light_red",
      },
      {
        name: RequestStatus.REQUEST_CANCELED,
        value: "Annulée",
        type: "light_red",
      },
      {
        name: RequestStatus.VALIDATED,
        value: "Validée",
        type: "light_green",
      },
      {
        name: RequestStatus.NO_VALIDATION_NEEDED,
        value: "Aucune validation nécessaire",
        type: "grey",
      },
      {
        name: RequestStatus.WAITING_FINANCING_VALIDATION,
        value: "En attente de financement",
        type: "light_purple",
      },
      {
        name: RequestStatus.ON_HOLD,
        value: "En cours de traitement",
        type: "light_blue",
      },
    ].find((x) => x.name === value);
  }

  function getRequestPriority(): IGeneric[] {
    return [
      {
        key: "p1",
        value: "P1 - Indispensable et urgent",
      },
      {
        key: "p2",
        value: "P2 - Indispensable et non urgent",
      },
      {
        key: "p3",
        value: "P3 - Non indispensable (pourquoi pas)",
      },
      {
        key: "p4",
        value: "P4 - Ne donnons pas suite",
      },
    ];
  }

  const footersActions: IFooterActions[] = [
    {
      name: "Traiter",
      logoName: "edit",
      action: showAddProjectFunnel,
    },
    {
      name: "Refuser",
      logoName: "cancel",
      action: refuseRequests,
    },
    {
      name: "Annuler",
      logoName: "close_custom",
      action: cancelRequests,
    },
    {
      name: "Supprimer",
      logoName: "delete_trash",
      action: removeRequests,
    },
  ];

  const removeFootersActions: IFooterActions[] = [
    {
      name: "Supprimer",
      logoName: "delete_trash",
      action: removeRequests,
    },
  ];

  function refuseRequests() {
    const requestStore = useRequestsStore();
    requestStore.show_refuse_modal = true;
  }

  function cancelRequests() {
    const requestStore = useRequestsStore();
    requestStore.show_cancel_modal = true;
  }

  function removeRequests() {
    const requestStore = useRequestsStore();
    requestStore.show_remove_modal = true;
  }

  async function showAddProjectFunnel() {
    const createProjectCoreStore = useCreateProjectCoreStore();
    const requestStore = useRequestsStore();
    const { project } = storeToRefs(createProjectCoreStore);
    const selectedUsers: IGetSelectedRequests = await requestStore.getSelectedUsers();

    project.value.source_type = ItemType.REQUEST;

    if (selectedUsers.requestsOfUsersWithMultipleRequests.length) {
      requestStore.duplicated_requests = selectedUsers.requestsOfUsersWithMultipleRequests;
      requestStore.show_duplicated_modal = true;
    } else {
      requestStore.show_duplicated_modal = false;
      createProjectCoreStore.openProjectModal();
      project.value.project_type = ProjectTypeEnum.NEW_PROJECT;
      project.value.users.users_id = selectedUsers.selectedRequests;
    }
  }

  return { headers, removeFootersActions, statusMapping, getRequestPriority, footersActions, showAddProjectFunnel };
};

export const useRequestsValidation = () => {
  async function validateManagerRequestsCreation() {
    const createRequestStore = useCreateRequestStore();
    const { request, isCheckedForOther } = storeToRefs(createRequestStore);
    const authStore = useAuthStore();

    const mandatoryFieldMessage = "Ce champ est obligatoire";

    const rules = computed(() => {
      return {
        // ...(!isCheckedForMe.value &&
        //   !isCheckedForOther.value && {
        //     isCheckedForOther: {
        //       required: helpers.withMessage("Vous devez sélectionner au moins l’une des deux options", required),
        //     },
        //   }),
        ...((authStore.isManager || authStore.isCompanyAdmin || authStore.isGroupViewer) && {
          users_to_invite: {
            required: helpers.withMessage("Sélectionner au moins une personne", required),
          },
          employee_comment: {
            required: helpers.withMessage(mandatoryFieldMessage, required),
          },
        }),
        ...(((!request.value.users_to_invite.length && isCheckedForOther.value) ||
          (request.value.users_to_invite.length &&
            request.value.users_to_invite.some((u) => u.user_id !== authStore.user?.user_id))) && {
          manager_priority: {
            required: helpers.withMessage(mandatoryFieldMessage, required),
          },
        }),
      };
    });

    const v$ = useVuelidate(rules, createRequestStore.request);
    await v$.value.$validate();

    return v$;
  }

  async function validateRequestsCreation() {
    const createRequestStore = useCreateRequestStore();
    const { request } = storeToRefs(createRequestStore);

    const mandatoryFieldMessage = "Ce champ est obligatoire";

    const rules = computed(() => {
      return {
        employee_comment: {
          required: helpers.withMessage(mandatoryFieldMessage, required),
        },
      };
    });

    const v$ = useVuelidate(rules, request);
    await v$.value.$validate();

    return v$;
  }

  return { validateManagerRequestsCreation, validateRequestsCreation };
};
